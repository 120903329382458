<template>
  <div>
    <b-overlay
      opacity="0.80"
      variant="transparent"
      blur="0.40rem"
      :show="show"
    >
      <template
        #overlay
      >
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col
          v-if="detail"
          cols="2"
        >
          <modal-ingreso
            :origen-data="'bodega'"
            :button-text="'Ingreso Manual'"
            :modal-titulo="'Ingresar Luminaria a Mantenimiento'"
            :placeholder="'Busqueda por Punto Iluminación o Luminaria...'"
            @refrescar-pagina="loadReport"
          />
        </b-col>
        <b-col
          v-if="detail"
          cols="2"
        >
          <div style="margin-top: 23px">
            <escaner-lector-mobile
              :origen="'mantenimientoBodega'"
              :titulo-modal="'Ingreso de Luminaria por QR'"
              :titulo-boton="'Ingreso por QR'"
              :titulo-instruccion="'¡Escanea el Código QR!'"
              :titulo-procesar="'Ingresar Luminaria Escaneada'"
              :modal-size="'lg'"
            />
          </div>
        </b-col>
        <b-col
          v-if="detail"
          cols="2"
        >
          <b-form-group>
            <label>Etapa:</label>
            <v-select
              v-model="etapa"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="detail"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  right
                  size="sm"
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="detail"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="detail"
          style="padding-top: 4px;"
          cols="1"
        >
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            title="Buscar"
            variant="primary"
            class="btn-icon"
            @click="loadReport()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          v-if="detail"
          style="padding-top: 3px;"
          cols="1"
        >
          <br>
          <table>
            <tr>
              <td>
                <reporte-general
                  :rows="rowsReporte"
                  :columns="columnsReporte"
                  :titulo-principal="tituloPrincipal"
                  :titulo-secundario="tituloSecundario"
                  :subtitulo="subtitulo"
                  :titulo-descarga="tituloDescarga"
                />
              </td>
              <td>
                <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  title="Descargar Reporte Lista PDF"
                  variant="primary"
                  class="btn-icon"
                  @click="generarDocumentoLista"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columnasBodega || columns"
            :rows="rows"
            style-class="vgt-table condensed"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar',
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'opciones'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <!--b-dropdown-item
                    v-if="props.row.estado === 'LUMINARIA DESPACHADA'"
                    @click="generarDocumento(props.row.id)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Imprimir Reporte</span>
                  </b-dropdown-item-->
                  <!--b-dropdown-item @click="subidaDocumentos(props.row.id)">
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50"
                    />
                    <span>Agregar Documentos</span>
                  </b-dropdown-item-->
                  <b-dropdown-item
                    @click="removerTicket(props.row)"
                  >
                    <Icon
                      :style="{fontSize: '16', marginTop: '-3px'}"
                      icon="material-symbols:delete-outline"
                    />
                    <span>DAR DE BAJA</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span v-else-if="props.column.field == 'estado'">
                <b-badge :variant="getColorClass(props.row)">
                  {{ estadosSeguimiento(props.row.estado) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'fechaCreacion'">
                <span>{{ calcularFecha(props.row.fechaCreacion) }}</span>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <!-- Modal Detalle -->
      <div>
        <b-modal
          id="modal-bodega"
          v-model="detalleBodega"
          title="Detalle de Recepción de Luminaria"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <detalle-bodega
            :luminaria="luminaria"
            @cerrar-detalle="cerrarDetalle"
          />
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-documentos"
          v-model="subidaDocumentosModal"
          title="Subir Documentos"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <subir-documentos-bodega :luminaria="luminaria" />
        </b-modal>
      </div>
      <!--div>
        <b-modal
          id="modal-documentos"
          v-model="etiquetaModal"
          title="Vista Previa de Etiqueta"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
          hide-footer
        >
          <etiqueta :luminaria="luminaria" />
        </b-modal>
      </div-->
      <div>
        <b-modal
          id="modal-selection"
          v-model="seleccionModal"
          title="Vista Previa de Etiqueta"
          size="sm"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
          hide-footer
        >
          <b-overlay :show="loadingReporte">
            <v-select
              label="nombre"
              :options="seleccionesReportes"
            />
            <hr>
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                @click="generarDocumentoLista"
              >
                Descargar Lista de Despachos
              </b-button>
            </div>
          </b-overlay>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-selection"
          v-model="verModalRemover"
          title="Vista Previa de Item a Remover"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
          hide-footer
        >
          <componente-remover
            origen="MANTENIMIENTOS"
            titulo-origen="este Mantenimiento"
            :item="luminaria"
            @actualizar="loadReport"
            @cerrar-modal="cerrarModalRemover"
          />
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton,
  BCol, BFormDatepicker, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BRow, VBTooltip, BFormSelect,
  BPagination, BDropdown, BBadge, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { calcularFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import {
  getLuminaria,
  generarReporte,
  generarReporteLista,
  findLuminarias, getListadoFallasLuminaria,
} from '@/utils/mantenimiento/bodega/bodegaUtils'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import { required } from '@core/utils/validations/validations'
import {
  subtitulo,
  tituloDescarga,
  tituloPrincipal,
  tituloSecundario,
  getRows,
  getColumns,
} from '@/utils/reportes/mantenimiento/bodega'
import DetalleBodega from '@/components/mantenimiento/bodega/detalle-bodega.vue'
import ModalIngreso from '@/components/ModaIngresoFormularios/ModalIngreso.vue'
import SubirDocumentosBodega from '@/components/mantenimiento/bodega/subirDocumentosBodega.vue'
import Etiqueta from '@/components/mantenimiento/bodega/etiqueta.vue'
import EscanerLectorMobile from '@/components/escanersQr/escanerLectorMobile.vue'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'
import ComponenteRemover from '@/components/bodega/componenteRemover.vue'

export default {
  name: 'MantenimientoBodega',
  components: {
    ComponenteRemover,
    EscanerLectorMobile,
    BCol,
    BRow,
    BBadge,
    vSelect,
    BButton,
    BOverlay,
    Etiqueta,
    BDropdown,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BPagination,
    ModalIngreso,
    VueGoodTable,
    BDropdownItem,
    DetalleBodega,
    ReporteGeneral,
    BFormDatepicker,
    BInputGroupAppend,
    SubirDocumentosBodega,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    detail: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    codigo: {
      type: String,
    },
    columnasBodega: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      verModalRemover: false,
      calcularFecha,
      required,
      loadingReporte: false,
      seleccionesReportes: [],
      seleccionModal: false,
      luminaria: null,
      showDetail: false,
      detalleBodega: false,
      subidaDocumentosModal: false,
      show: false,
      modalQR: false,
      verModalPreDiagnostico: false,
      fechaInicio: '',
      fechaFin: '',
      etapa: '',
      option: [
        { title: 'Todas', value: 'TODOS' },
        { title: 'Ingresadas', value: 'INGRESADA' },
        { title: 'Reparación', value: 'REPARACIÓN' },
        { title: 'Garantía', value: 'GARANTÍA' },
        { title: 'Reparadas', value: 'REPARADA' },
        { title: 'Mantenimientos de Luminarias', value: 'MANTENIMIENTO DE LUMINARIA' },
        { title: 'Luminarias para Despacho', value: 'LUMINARIA PARA DESPACHO' },
        { title: 'Luminarias Despachadas', value: 'LUMINARIA DESPACHADA' },
      ],
      columns: [
        {
          label: 'Tipo Lámpara',
          field: 'modelo.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie Lámpara',
          field: 'codigo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Modelo Lámpara',
          field: 'modeloLampara.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Tipo',
          field: 'tipoLampara.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      preDiagnostico: {
        preDiagnostico: null,
        observaciones: null,
        usuario: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      fotoPreDiagnostico: '',
      rows: [],
      rowsReporte: [],
      columnsReporte: [],
      rutas: [
        {
          id: 1,
          nombre: 'Se Envía a Garantía',
        },
        {
          id: 2,
          nombre: 'Se Envía a Laboratorio APVN',
        },
      ],
      pageLength: 15,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  async created() {
    this.rows = []
    this.estados = getListadoFallasLuminaria(6)
    this.seleccionesReportes = getListadoFallasLuminaria(7)
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
    this.show = false
  },
  methods: {
    estadosSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    async loadReport() {
      this.show = true
      await this.$nextTick()
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
      const filter = {
        where: {
          and: [
            { companyId: this.usuario.idCompany },
            !this.detail ? {} : { fechaCreacion: { gte: fechaInicio.toISOString() } },
            !this.detail ? {} : { fechaCreacion: { lte: fechaFin.toISOString() } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.codigo && this.codigo !== '') {
        filter.where.and.push({ codigo: this.codigo })
      }

      if (this.etapa && this.etapa.value !== '') {
        if (this.etapa.value !== 'TODOS') {
          filter.where.and.push({ estado: this.etapa.value })
        }
      }

      const data = await findLuminarias(filter)
      this.rowsReporte = getRows([...data])
      this.show = false
      this.rows = data

      this.columnsReporte = getColumns()

      this.show = false
    },
    async generarDocumento(id) {
      try {
        this.show = true
        const luminaria = await getLuminaria(id)
        const fileResumen = await generarReporte(luminaria)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Mantenimiento de Luminaria.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado!')
      } catch (error) {
        console.error(error)
        notificacionError('Algo ha ocurrido, intentalo nuevamente!')
      } finally {
        this.show = false
      }
    },
    async generarDocumentoLista() {
      try {
        this.show = true
        const luminarias = await findLuminarias({
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { estado: 'LUMINARIA DESPACHADA' },
              { fechaCreacion: { gte: this.fechaInicio } },
              { fechaCreacion: { lte: this.fechaFin } },
            ],
          },
        })
        if (luminarias.length === 0) {
          notificacionError('No hay luminarias en el estado especificado.')
          return
        }
        const fileResumen = await generarReporteLista(luminarias)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'Mantenimiento de Luminaria.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado!')
      } catch (error) {
        notificacionError('Algo ha ocurrido, intentalo nuevamente!')
        console.error(error)
      } finally {
        this.seleccionModal = false
        this.show = false
      }
    },
    async abrirModalQR() {
      try {
        this.modalQR = true
      } catch (error) {
        console.error(error)
      }
    },
    removerTicket(params) {
      const luminaria = JSON.parse(JSON.stringify(params))
      delete luminaria.vgt_id
      delete luminaria.originalIndex
      if (luminaria) {
        this.luminaria = luminaria
        this.verModalRemover = true
      }
    },
    cerrarModalRemover() {
      this.verModalRemover = false
    },
    getColorClass(row) {
      // eslint-disable-next-line no-mixed-operators
      if (row && row.estado === 'INGRESADA') {
        return 'light-danger'
        // eslint-disable-next-line no-mixed-operators
      } if (row && row.estado === 'REPARACIÓN' || row && row.estado === 'GARANTÍA') {
        return 'light-warning'
      } if (row && row.estado === 'REPARADA') {
        return 'light-primary'
      } if (row && row.estado === 'MANTENIMIENTO DE LUMINARIA') {
        return 'light-secondary'
      } if (row && row.estado === 'LUMINARIA PARA DESPACHO') {
        return 'light-info'
      } if (row && row.estado === 'LUMINARIA DESPACHADA') {
        return 'light-success'
      } if (row && row.estado === 'DESCARTADA') {
        return 'danger'
      } if (row && row.estado === 'PINTURA') {
        return 'light-info'
      }
      return ''
    },
    async showDetalle(params) {
      const luminaria = params.row
      if (luminaria) {
        console.log(luminaria.id)
        this.luminaria = luminaria
        this.detalleBodega = true
      }
    },
    cerrarDetalle() {
      this.loadReport()
      this.detalleBodega = false
    },
    imagenPreDiagnostico(file) {
      this.fotoPreDiagnostico = file
    },
  },
}

</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
